<template>
  <div class="data_center">
    <!--  顶部的切换按钮 -->
    <div class="top_menu">
      <el-tabs v-model="activeIndex" @tab-click="handleClick">
        <el-tab-pane v-for="item in tabArr" :label="item.tableName" :name="item.index"></el-tab-pane>
      </el-tabs>
    </div>
    <!-- 组件切换部分 -->
    <div class="show_content">
      <!-- <KeepAlive> -->
      <component :is="compontName"></component>
      <!-- </KeepAlive> -->
    </div>
  </div>
</template>

<script>
import TeacherData from "./TeacherData.vue";
import StudentData from "./StudentData.vue";
import ClassData from "./ClassData.vue";
export default {
  data() {
    return {
      //tab栏激活的名称
      activeIndex: "0",

      //tab选项
      tabArr: [
        {
          tableName: "教师数据汇总表",
          index: "0"
        },
        {
          tableName: "续费课消考勤数据表",
          index: "1"
        },
        {
          tableName: "班级学生出勤及续费数据表",
          index: "2"
        }
      ],
      //激活的组件名称
      compontName: "TeacherData"
    };
  },
  created() {},
  mounted() {},
  methods: {
    //tab栏的点击事件
    handleClick(tab, event) {
      switch (this.activeIndex) {
        case "0":
          this.compontName = "TeacherData";
          break;
        case "1":
          this.compontName = "StudentData";
          break;
        case "2":
          this.compontName = "ClassData";
          break;
      }
    }
  },
  computed: {},
  watch: {},
  components: {
    TeacherData,
    StudentData,
    ClassData
  }
};
</script>

<style lang="scss" scoped>
.data_center {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .top_menu {
    margin-left: 14px;

    ::v-deep .el-tabs__header {
      margin: 0;
    }
    ::v-deep .el-tabs__nav-wrap::after {
      height: 0;
    }
  }

  .show_content {
    flex: 1;
  }
}
</style>
