<!-- 续费课消请假补课表 不需要导出功能 -->
<template>
  <div class="student_data">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="student_data_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_action">
            <div class="data_picker">
              <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" v-model="selectMonth" type="month" placeholder="选择月">
              </el-date-picker>
            </div>

            <div class="flex_1"></div>

            <!-- 导出按钮 -->
            <!-- <el-button :loading="showExporLoading" v-if="tableData && tableData.length > 0" type="primary" size="medium" @click.stop="doExportAction"
              >导出班级出勤及续费数据表</el-button
            > -->
          </div>

          <!-- 内容部分 -->
          <div class="table_content margin_top_10">
            <!-- 顶部的分栏  -->
            <div class="table_tilte">
              <div class="dv_one">正式生续费数据表</div>
              <div class="dv_two">课消表</div>
              <div class="dv_three">请假补课数据表</div>
            </div>
            <!-- 表格部分 -->
            <el-table v-bind="bindProps()" style="width: 100%; margin: auto" border stripe :data="tableData">
              <!-- 时间 -->
              <el-table-column prop="time" label="时间" align="center" width="80"></el-table-column>
              <!--  结业学员数 -->
              <el-table-column prop="complete_num" label="结业学员数" align="center" width="100"></el-table-column>
              <!-- 已续费人数 -->
              <el-table-column prop="renew_num" label="已续费人数" align="center" width="100"></el-table-column>
              <!-- 流失人数 -->
              <el-table-column prop="loss_num" label="流失人数" align="center" width="100"> </el-table-column>
              <!-- 流失详情 -->
              <el-table-column show-overflow-tooltip prop="loss_names" label="流失详情" align="center" width="100"></el-table-column>
              <!-- 目标课消数 -->
              <el-table-column prop="target_elimination_num" label="目标课消数" align="center" width="100"></el-table-column>
              <!-- 实际课消数 -->
              <el-table-column prop="real_elimination_num" label="实际课消数" align="center" width="100"> </el-table-column>
              <!-- 应出勤人次 -->
              <el-table-column prop="attendance_num" label="应出勤人次" align="center" width="100"></el-table-column>
              <!-- 实际出勤人次 -->
              <el-table-column prop="real_attendance_num" label="实际出勤人次" align="center" width="120"></el-table-column>
              <!-- 实际课消完成比 -->
              <el-table-column prop="real_class_elimination" label="实际课消完成比" align="center" width="100"></el-table-column>
              <!-- 请假缺课人次 -->
              <el-table-column prop="leave_num" label="请假缺课人次" align="center"></el-table-column>
              <!-- 补课人次 -->
              <el-table-column prop="make_up_num" label="补课人次" align="center"></el-table-column>
              <!-- 待补课人次 -->
              <el-table-column prop="wait_make_up_num" label="待补课人次" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { getStudentData } from "@/api/Api.js";
import { export_json_to_excel } from "@/Excel/Export2Excel";
export default {
  name: "TeacherData",
  data() {
    return {
      //页面加载loading
      showLoading: 1,

      //选择的月份
      selectMonth: "",

      //表格的数据
      tableData: [],

      //总的数据条数
      totalSize: 0,

      //表格的最大高度
      maxHeight: 0,

      //导出的加载框
      showExporLoading: false,

      // 日期选择器选择月份 只能选择当前月份的12个月份
      pickerOptions: {
        disabledDate(val) {
          const month = new Date(val).getMonth();
          const currentMonth = new Date().getMonth();
          const year = new Date(val).getFullYear();
          const currentYear = new Date().getFullYear();
          const currenTime = new Date().getTime();
          const time = new Date(val).getTime();

          if (year < currentYear - 1 || time > currenTime || (year < currentYear && month - 1 < currentMonth)) {
            return true;
          }
        }
      },

      //导出的加载框
      showExporLoading: false,

      //导出表格的表头
      tHeaderArr: [
        "时间",
        "结业学员数",
        "已续费人数",
        "流失人数",
        "流失详情",
        "目标课消数",
        "实际课消数",
        "应出勤人次",
        "实际出勤人次",
        "实际课消完成比",
        "请假缺课人次",
        "补课人次",
        "待补课人次"
      ],

      //导出表格的字段
      tBodyValue: [
        "time",
        "complete_num",
        "renew_num",
        "loss_num",
        "loss_names",
        "target_elimination_num",
        "real_elimination_num",
        "attendance_num",
        "real_attendance_num",
        "real_class_elimination",
        "leave_num",
        "make_up_num",
        "wait_make_up_num"
      ]
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    this.httpData(this.selectMonth);
  },
  methods: {
    //初始化表格的最大高度
    initData() {
      this.$nextTick(() => {
        this.maxHeight = document.documentElement.clientHeight - 35 - 76 - 44 - 40;
      });

      //初始化进入时显示上一个月的数据
      this.selectMonth = this.getLastMonthDate();
    },

    //获取当前月份的上一个月
    getLastMonthDate() {
      var nowdays = new Date();
      var year = nowdays.getFullYear();
      var month = nowdays.getMonth();
      if (month == 0) {
        month = 11;
        year = year - 1;
      }
      if (month < 10) {
        month = `0${month}`;
      }

      return year + "-" + month + "-01";
    },

    //导出数据表的操作
    doExportAction() {
      //查询页面同步为1,查询所有数据
      this.showExporLoading = true;
      this.httpData(this.selectMonth, true);
    },

    /**
     * 请求表格数据
     * startDate 开始时间（不填默认当前月）
     */
    httpData(startDate, isExport = false) {
      getStudentData(startDate).then(tableList => {
        if (tableList.data.state == 200) {
          //获取表格数据
          let serverData = tableList.data.data.infoList;
          //获取总的条数
          this.totalSize = tableList.data.data.size;
          //转换数据
          this.assembleData(serverData, isExport);
        } else {
          this.showErrorMsg(tableList.data.msg);
        }
      });
    },

    /**
     * 将服务器返回的表格数据重新组装
     */
    assembleData(serverData, isExport = false) {
      if (!isExport) {
        //非导出,将表格的当前页数据清空
        this.tableData.splice(0, this.tableData.length);
      }
      if (this.isEmpty(serverData)) {
        return;
      }
      serverData.forEach(item => {
        //流失详情
        if (!this.isEmpty(item.loss_names)) {
          item.loss_names = item.loss_names.join("、");
        } else {
          item.loss_names = "-";
        }
        if (!isExport) {
          //非导出
          this.tableData.push(item);
        }
      });

      if (isExport) {
        //导出的操作
        this.exportExcel(serverData);
      }
    },

    /**
     * 导出表格的方法
     * exportArr 表格的数据
     */
    exportExcel(exportArr) {
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        //把要导出的数据tableData存到list
        const data = this.formatJson(filterVal, exportArr);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, "续费课消考勤数据表");
        setTimeout(() => {
          this.showExporLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    //动态给表格添加maxheight
    bindProps() {
      if (this.tableData.length > 7) {
        return {
          maxHeight: this.maxHeight
        };
      } else return {};
    }
  },
  computed: {},
  watch: {
    //监听选中的日期
    selectMonth: function(newValue, oldValue) {
      //刷新数据
      this.httpData(newValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.student_data {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 14px;
  ::v-deep .el-date-editor.el-input {
    width: 160px;
  }

  .top_action {
    display: flex;
    align-items: center;
  }

  .table_tilte {
    box-sizing: border-box;
    height: 60px;
    width: 100%;
    display: flex;
    border-top: 1px solid #ebeef5;
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
    .dv_one {
      width: 367px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      border-right: 1px solid #ebeef5;
      padding: 0 6px;
    }
    .dv_two {
      width: 620px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      border-right: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .dv_three {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      font-weight: bold;
      box-sizing: border-box;
    }
  }
}
</style>
