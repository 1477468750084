import { render, staticRenderFns } from "./TeacherData.vue?vue&type=template&id=7ff41507&scoped=true&"
import script from "./TeacherData.vue?vue&type=script&lang=js&"
export * from "./TeacherData.vue?vue&type=script&lang=js&"
import style0 from "./TeacherData.vue?vue&type=style&index=0&id=7ff41507&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ff41507",
  null
  
)

export default component.exports