<!-- 班级学生出勤及续费数据表 需要导出功能 -->
<template>
  <div class="class_data">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="class_data_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_action">
            <div class="data_picker">
              <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" v-model="selectMonth" type="month" placeholder="选择月">
              </el-date-picker>
            </div>

            <!-- 选择老师 -->
            <div class="margin_left_40">
              <span class="margin_right_4">教师：</span>
              <el-select @change="changeState" v-model="seclectTeacherName" placeholder="请选择">
                <el-option v-for="item in teacherArr" :label="item.name" :value="item.name"> </el-option>
              </el-select>
            </div>

            <div class="flex_1"></div>

            <!-- 导出按钮 -->
            <el-button :loading="showExporLoading" v-if="tableData && tableData.length > 0" type="primary" size="medium" @click.stop="doExportAction"
              >导出班级出勤及续费数据表</el-button
            >
          </div>

          <!-- 表格 -->
          <div class="table_content margin_top_10">
            <el-table ref="classTableList" @sort-change="sortChange" style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 班级名称 -->
              <el-table-column show-overflow-tooltip prop="class_name" label="班级名称" align="center" width="100"></el-table-column>
              <!--  应出勤人次 -->
              <el-table-column prop="total_num" label="应出勤人次" align="center"></el-table-column>
              <!-- 实际出勤人数 -->
              <el-table-column prop="attendance_num" label="实际出勤人次" align="center"></el-table-column>
              <!-- 请假人数 -->
              <el-table-column prop="leave_num" label="请假缺课未完成补课人次" align="center"> </el-table-column>
              <!-- 出勤率 -->
              <el-table-column prop="attendance_present" sortable="cutsom" label="出勤率" align="center" width="102"> </el-table-column>
              <!-- 到期学员数 -->
              <el-table-column prop="expire_student_num" label="到期学员数" align="center"></el-table-column>
              <!-- 已续费学员数 -->
              <el-table-column prop="expenditure_student_num" label="已续费学员数" align="center"></el-table-column>
              <!-- 流失学员数 -->
              <el-table-column prop="lost_studnet_num" label="流失学员数" align="center"></el-table-column>
            </el-table>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { getTeacherList, getClassData } from "@/api/Api.js";
import { export_json_to_excel } from "@/Excel/Export2Excel";
import axios from "axios";
export default {
  name: "ClassData",
  data() {
    return {
      //页面加载loading
      showLoading: 1,

      //选择的月份
      selectMonth: "",

      //选中的老师名称,默认所有
      seclectTeacherName: "所有",

      //所有老师的数据
      teacherArr: [],

      //表格的数据
      tableData: [],

      //总的数据条数
      totalSize: 0,

      //表格的最大高度
      maxHeight: 0,

      //导出的加载框
      showExporLoading: false,

      // 日期选择器选择月份 只能选择当前月份之前的12个月份
      pickerOptions: {
        disabledDate(val) {
          const month = new Date(val).getMonth();
          const currentMonth = new Date().getMonth();
          const year = new Date(val).getFullYear();
          const currentYear = new Date().getFullYear();
          const currenTime = new Date().getTime();
          const time = new Date(val).getTime();

          if (year < currentYear - 1 || time > currenTime || (year < currentYear && month - 1 < currentMonth)) {
            return true;
          }
        }
      },

      //选择的老师id
      tid: "",

      //排序规则
      orderType: "",

      //排序字段
      orderField: "",

      //导出表格的表头
      tHeaderArr: ["班级名称", "应出勤人次", "实际出勤人次", "请假缺课未完成补课人次", "出勤率", "到期学员数", "已续费学员数", "流失学员数"],

      //导出表格的字段
      tBodyValue: [
        "class_name",
        "total_num",
        "attendance_num",
        "leave_num",
        "attendance_present",
        "expire_student_num",
        "expenditure_student_num",
        "lost_studnet_num"
      ]
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    //获取页面数据
    this.getData();
  },
  methods: {
    //初始化表格的最大高度
    initData() {
      this.$nextTick(() => {
        this.maxHeight = document.documentElement.clientHeight - 35 - 76 - 44 - 40;
      });

      //初始化进入时显示上一个月的数据
      this.selectMonth = this.getLastMonthDate();
    },

    //老师下拉框的选中事件
    changeState() {
      let teacherItem = this.teacherArr.find(item => {
        return item.name == this.seclectTeacherName;
      });
      if (this.isEmpty(teacherItem)) {
        return;
      }
      if (teacherItem.name != "所有") {
        this.tid = teacherItem.id;
      } else {
        this.tid = "";
      }

      //还原表格的排序
      this.clearTable();

      //刷新数据
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType);
    },

    //重置表格的表虚
    clearTable() {
      this.$refs.classTableList.clearSort();
    },

    //获取当前月份的上一个月
    getLastMonthDate() {
      var nowdays = new Date();
      var year = nowdays.getFullYear();
      var month = nowdays.getMonth();
      if (month == 0) {
        month = 11;
        year = year - 1;
      }
      if (month < 10) {
        month = `0${month}`;
      }

      return year + "-" + month + "-01";
    },

    //当表格的排序条件发生变化的时候会触发该事件
    sortChange({ column, prop, order }) {
      if (order == "descending") {
        //降序
        this.orderType = "ESC";
        this.orderField = prop;
      } else if (order == "ascending") {
        //升序
        this.orderType = "ASC";
        this.orderField = prop;
      } else {
        this.orderType = "";
        this.orderField = "";
      }
      //刷新数据
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType);
    },

    //初次进入时获取教师列表和表格数据
    getData() {
      axios.all([getTeacherList(), getClassData(this.tid, this.selectMonth, this.orderField, this.orderType)]).then(
        axios.spread((teacherList, tableList) => {
          //获取老师数据
          if (teacherList.data.state == 200) {
            this.teacherArr = teacherList.data.data;
            this.teacherArr.unshift({ id: "", name: "所有" });
          } else {
            this.showErrorMsg(teacherList.data.msg);
          }
          //获取表格数据
          if (tableList.data.state == 200) {
            //获取表格数据
            let serverData = tableList.data.data.infoList;
            //获取总的条数
            this.totalSize = tableList.data.data.size;
            //初始化表格数据
            this.tableData.splice(0, this.tableData.length);
            serverData.forEach(item => {
              //班级名称
              if (this.isEmpty(item.class_name)) {
                item.class_name = "-";
              }
              this.tableData.push(item);
              console.log("表格数据是:", this.tableData);
            });
          } else {
            this.showErrorMsg(tableList.data.msg);
          }
        })
      );
    },

    /**
     * 请求表格数据
     * tid 教师id（指定教师时用）
     * startDate 开始时间（不填默认当前月）
     * orderField 排序字段(对应字段名）：attendance_percent 实际出勤率；class_elimination_real 当月实际课消数；new_add_number 新增正式生人数；old_add_number 续费人数
     * orderType 排序规则 ASC：升序   ESC或其他：降序
     * isExport 是否是导出的操作
     */
    httpData(tid, startDate, orderField, orderType, isExport = false) {
      getClassData(tid, startDate, orderField, orderType).then(tableList => {
        if (tableList.data.state == 200) {
          //获取表格数据
          let serverData = tableList.data.data.infoList;
          //获取总的条数
          this.totalSize = tableList.data.data.size;
          //初始化表格数据
          this.tableData.splice(0, this.tableData.length);
          serverData.forEach(item => {
            //班级名称
            if (this.isEmpty(item.class_name)) {
              item.class_name = "-";
            }
            this.tableData.push(item);

            if (isExport) {
              //导出
              this.exportExcel(this.tableData);
            }
          });
        } else {
          this.showErrorMsg(tableList.data.msg);
        }
      });
    },

    //导出数据表的操作
    doExportAction() {
      //查询页面同步为1,查询所有数据
      this.showExporLoading = true;
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType, true);
    },

    /**
     * 导出表格的方法
     * exportArr 表格的数据
     */
    exportExcel(exportArr) {
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        //把要导出的数据tableData存到list
        const data = this.formatJson(filterVal, exportArr);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, `${this.selectMonth}-${this.seclectTeacherName}-班级学生出勤及续费数据表`);
        setTimeout(() => {
          this.showExporLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    }
  },
  computed: {},
  watch: {
    //监听选中的日期
    selectMonth: function(newValue, oldValue) {
      //还原表格的排序
      this.clearTable();
      //刷新数据
      this.httpData(this.tid, newValue, this.orderField, this.orderType);
    }
  }
};
</script>

<style lang="scss" scoped>
.class_data {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 14px;
  ::v-deep .el-date-editor.el-input {
    width: 160px;
  }

  .top_action {
    display: flex;
    align-items: center;
  }

  .head_arrow {
    position: relative;
    .icon-common {
      position: absolute;
      font-size: 14px;
      right: 0;
      bottom: 0px;
      cursor: pointer;
      color: #409eff;
    }
  }
}
</style>
