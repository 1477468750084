<!-- 教师数据汇总表 需要导出功能 -->
<template>
  <div class="teacher_data">
    <Layout :showLoading="showLoading">
      <template v-slot:content>
        <div class="teacher_data_content">
          <!-- 顶部的操作框部分 -->
          <div class="top_action">
            <div class="data_picker">
              <el-date-picker :picker-options="pickerOptions" value-format="yyyy-MM-dd" v-model="selectMonth" type="month" placeholder="选择月">
              </el-date-picker>
            </div>

            <!-- 选择老师 -->
            <div class="margin_left_40">
              <span class="margin_right_4">教师：</span>
              <el-select @change="changeState" v-model="seclectTeacherName" placeholder="请选择">
                <el-option v-for="item in teacherArr" :label="item.name" :value="item.name"> </el-option>
              </el-select>
            </div>

            <div class="flex_1"></div>

            <!-- 导出按钮 -->
            <el-button :loading="showExporLoading" v-if="tableData && tableData.length > 0" type="primary" size="medium" @click.stop="doExportAction"
              >导出教师数据汇总表</el-button
            >
          </div>

          <!-- 表格 -->
          <div class="table_content margin_top_10">
            <el-table ref="tableList" @sort-change="sortChange" style="width: 100%; margin: auto" border stripe :data="tableData" :max-height="maxHeight">
              <!-- 序号  -->
              <el-table-column type="index" label="序号" align="center" width="60">
                <template scope="scope">
                  <span>{{ (currPage - 1) * pageSize + scope.$index + 1 }}</span>
                </template>
              </el-table-column>

              <!-- 教师 -->
              <el-table-column prop="teacher_name" show-overflow-tooltip label="教师" align="center"></el-table-column>
              <!--  应出勤人数 -->
              <el-table-column prop="attendance_num_total" label="应出勤人次" align="center"></el-table-column>
              <!-- 当月实际出勤人数 -->
              <el-table-column prop="attendance_num_real" label="当月实际出勤人次" align="center"></el-table-column>
              <!-- 当月实际出勤率 sortable -->
              <el-table-column prop="attendance_percent" sortable="custom" label="当月实际出勤率" align="center" width="95"> </el-table-column>
              <!-- 当月请假缺课未完成补课人次 -->
              <el-table-column prop="make_up_lesson" label="当月请假缺课未完成补课人次" align="center" width="120"></el-table-column>
              <!-- 当月目标课消数 -->
              <el-table-column prop="class_elimination_total" label="当月目标课消数" align="center"></el-table-column>
              <!-- 当月实际课消数 -->
              <el-table-column sortable="custom" prop="class_elimination_real" label="当月实际课消数" align="center" width="92"> </el-table-column>
              <!-- 当月流失人数 -->
              <el-table-column prop="lossing_number" label="当月流失人数" align="center"></el-table-column>
              <!-- 当月新增正式生人数 -->
              <el-table-column prop="new_add_number" sortable="custom" label="当月新增正式生人数" align="center" width="110"> </el-table-column>
              <!-- 当月老生续费人数 -->
              <el-table-column prop="old_add_number" sortable="custom" label="当月老生续费人数" align="center" width="104"> </el-table-column>
              <!-- 当月新增正式名单 -->
              <el-table-column show-overflow-tooltip prop="add__new_names" label="当月新增正式生名单" align="center"></el-table-column>
              <!-- 当月续费老生名单 -->
              <el-table-column show-overflow-tooltip prop="add_old_names" label="当月续费老生名单" align="center"></el-table-column>
              <!-- 当月流失名单 -->
              <el-table-column show-overflow-tooltip prop="lossing_names" label="当月流失名单" align="center"></el-table-column>
            </el-table>
          </div>

          <!--分页器部分  -->
          <div class="bom_page margin_bom_20" v-if="tableData && tableData.length > 0">
            <el-pagination
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currPage"
              :page-sizes="pageSizeArr"
              :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="totalSize"
            >
            </el-pagination>
          </div>
        </div>
      </template>
    </Layout>
  </div>
</template>

<script>
import { export_json_to_excel } from "@/Excel/Export2Excel";
import { getTeacherList, getTeacherTableData } from "@/api/Api.js";
import axios from "axios";
export default {
  name: "TeacherData",
  data() {
    return {
      //页面加载loading
      showLoading: 1,

      //选择的月份
      selectMonth: "",

      //选中的老师名称,默认所有
      seclectTeacherName: "所有",

      //所有老师的数据
      teacherArr: [],

      //表格的数据
      tableData: [],

      //总的数据条数
      totalSize: 0,

      //条数选择的数据
      pageSizeArr: [10, 12, 14],

      //每页显示的条数
      pageSize: 10,

      //当前显示的页码
      currPage: 1,

      //表格的最大高度
      maxHeight: 0,

      //导出的加载框
      showExporLoading: false,

      // 日期选择器选择月份 只能选择当前月份12个月份
      pickerOptions: {
        disabledDate(val) {
          const month = new Date(val).getMonth();
          const currentMonth = new Date().getMonth();
          const year = new Date(val).getFullYear();
          const currentYear = new Date().getFullYear();
          const currenTime = new Date().getTime();
          const time = new Date(val).getTime();

          if (year < currentYear - 1 || time > currenTime || (year < currentYear && month - 1 < currentMonth)) {
            return true;
          }
        }
      },

      //导出表格的表头
      tHeaderArr: [
        "教师",
        "应出勤人次",
        "当月实际出勤人次",
        "当月实际出勤率",
        "当月请假缺课未完成补课人次",
        "当月目标课消数",
        "当月实际课消数",
        "当月流失人数",
        "当月新增正式生人数",
        "当月老生续费人数",
        "当月新增正式生名单",
        "当月续费老生名单",
        "当月流失名单"
      ],

      //导出表格的字段
      tBodyValue: [
        "teacher_name",
        "attendance_num_total",
        "attendance_num_real",
        "attendance_percent",
        "make_up_lesson",
        "class_elimination_total",
        "class_elimination_real",
        "lossing_number",
        "new_add_number",
        "old_add_number",
        "add__new_names",
        "add_old_names",
        "lossing_names"
      ],

      //选择的老师id
      tid: "",

      //排序规则
      orderType: "",

      //排序字段
      orderField: ""
    };
  },
  created() {
    //初始化数据
    this.initData();
  },
  mounted() {
    //获取页面数据
    this.getData();
  },
  methods: {
    //初始化表格的最大高度
    initData() {
      this.$nextTick(() => {
        this.maxHeight = document.documentElement.clientHeight - 35 - 76 - 44 - 90;
      });

      //初始化进入时显示上一个月的数据
      this.selectMonth = this.getLastMonthDate();
    },

    //老师下拉框的选中事件
    changeState() {
      let teacherItem = this.teacherArr.find(item => {
        return item.name == this.seclectTeacherName;
      });
      if (this.isEmpty(teacherItem)) {
        return;
      }
      if (teacherItem.name != "所有") {
        this.tid = teacherItem.id;
      } else {
        this.tid = "";
      }
      this.currPage = 1;
      //还原表格的排序
      this.clearTable();
      //刷新数据
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType, this.currPage, this.pageSize);
    },

    //每页显示的条数变化时触发
    handleSizeChange(val) {
      this.pageSize = val;
      this.currPage = 1;
      //重新刷新数据
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType, this.currPage, this.pageSize);
    },

    //页码改变时触发
    handleCurrentChange(val) {
      this.currPage = val;
      //重新刷新数据
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType, this.currPage, this.pageSize);
    },

    //获取当前月份的上一个月
    getLastMonthDate() {
      var nowdays = new Date();
      var year = nowdays.getFullYear();
      var month = nowdays.getMonth();
      if (month == 0) {
        month = 11;
        year = year - 1;
      }
      if (month < 10) {
        month = `0${month}`;
      }

      return year + "-" + month + "-01";
    },

    //导出数据表的操作
    doExportAction() {
      //查询页面同步为1,查询所有数据
      this.currPage = 1;
      this.pageSize = this.totalSize;
      this.showExporLoading = true;
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType, this.currPage, this.pageSize, true);
    },

    /**
     * 导出表格的方法
     * exportArr 表格的数据
     */
    exportExcel(exportArr) {
      require.ensure([], () => {
        //定义表头
        const tHeader = this.tHeaderArr;
        //定义表格数据的可想的属性key值
        const filterVal = this.tBodyValue;
        //把要导出的数据tableData存到list
        const data = this.formatJson(filterVal, exportArr);
        //导出操作最后一个是表名字
        export_json_to_excel(tHeader, data, "教师数据汇总表");
        setTimeout(() => {
          this.showExporLoading = false;
        }, 2000);
      });
    },

    //格式化json的方法
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]));
    },

    //当表格的排序条件发生变化的时候会触发该事件
    sortChange({ column, prop, order }) {
      if (order == "descending") {
        //降序
        this.orderType = "ESC";
        this.orderField = prop;
      } else if (order == "ascending") {
        //升序
        this.orderType = "ASC";
        this.orderField = prop;
      } else {
        this.orderType = "";
        this.orderField = "";
      }
      //刷新数据
      this.httpData(this.tid, this.selectMonth, this.orderField, this.orderType, this.currPage, this.pageSize);
    },

    //重置表格的表虚
    clearTable() {
      this.$refs.tableList.clearSort();
    },

    //初次进入时获取教师列表和表格数据
    getData() {
      axios.all([getTeacherList(), getTeacherTableData(this.tid, this.selectMonth, this.orderField, this.orderType, this.currPage, this.pageSize)]).then(
        axios.spread((teacherList, tableList) => {
          //获取老师数据
          if (teacherList.data.state == 200) {
            this.teacherArr = teacherList.data.data;
            this.teacherArr.unshift({ id: "", name: "所有" });
          } else {
            this.showErrorMsg(teacherList.data.msg);
          }
          //获取表格数据
          if (tableList.data.state == 200) {
            //获取表格数据
            let serverData = tableList.data.data.infoList;
            //获取总的条数
            this.totalSize = tableList.data.data.size;
            //转换数据
            this.assembleData(serverData);
          } else {
            this.showErrorMsg(tableList.data.msg);
          }
        })
      );
    },

    /**
     * 请求表格数据
     * tid 教师id（指定教师时用）
     * startDate 开始时间（不填默认当前月）
     * orderField 排序字段(对应字段名）：attendance_percent 实际出勤率；class_elimination_real 当月实际课消数；new_add_number 新增正式生人数；old_add_number 续费人数
     * orderType 排序规则 ASC：升序   ESC或其他：降序
     * pageIndex 页码
     * pageNum 每页条数
     */
    httpData(tid, startDate, orderField, orderType, pageIndex, pageNum, isExport = false) {
      getTeacherTableData(tid, startDate, orderField, orderType, pageIndex, pageNum).then(tableList => {
        if (tableList.data.state == 200) {
          //获取表格数据
          let serverData = tableList.data.data.infoList;
          //获取总的条数
          this.totalSize = tableList.data.data.size;
          //转换数据
          this.assembleData(serverData, isExport);
        } else {
          this.showErrorMsg(tableList.data.msg);
        }
      });
    },

    /**
     * 将服务器返回的表格数据重新组装
     */
    assembleData(serverData, isExport = false) {
      if (!isExport) {
        //非导出,将表格的当前页数据清空
        this.tableData.splice(0, this.tableData.length);
      }
      if (this.isEmpty(serverData)) {
        return;
      }
      serverData.forEach(item => {
        //教师
        if (this.isEmpty(item.teacher_name)) {
          item.teacher_name = "-";
        }

        //当月流失名单
        if (!this.isEmpty(item.lossing_names)) {
          item.lossing_names = item.lossing_names.join("、");
        } else {
          item.lossing_names = "-";
        }

        //当月新增正式名单
        if (!this.isEmpty(item.add__new_names)) {
          item.add__new_names = item.add__new_names.join("、");
        } else {
          item.add__new_names = "-";
        }

        //当月续费老生名单
        if (!this.isEmpty(item.add_old_names)) {
          item.add_old_names = item.add_old_names.join("、");
        } else {
          item.add_old_names = "-";
        }
        if (!isExport) {
          //非导出
          this.tableData.push(item);
        }
      });

      if (isExport) {
        //导出的操作
        this.exportExcel(serverData);
      }
    }
  },
  computed: {},
  watch: {
    //监听选中的日期
    selectMonth: function(newValue, oldValue) {
      //还原表格的排序
      this.clearTable();
      //刷新数据
      this.httpData(this.tid, newValue, this.orderField, this.orderType, this.currPage, this.pageSize);
    }
  }
};
</script>

<style lang="scss" scoped>
.teacher_data {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 10px 14px;
  ::v-deep .el-date-editor.el-input {
    width: 160px;
  }

  .top_action {
    display: flex;
    align-items: center;
  }

  .head_arrow {
    position: relative;
    .icon-common {
      position: absolute;
      font-size: 14px;
      right: 0;
      bottom: 0px;
      cursor: pointer;
      color: #409eff;
    }
  }
}
</style>
